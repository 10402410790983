import React from "react"
import * as S from "./HomepageHero.styles.jsx"
import parse from "html-react-parser"
import { StaticImage } from "gatsby-plugin-image"

const HomepageHero = ({
  title,
  content,
  video,
  videoPoster,
  height,
  autoplay,
}) => {
  return (
    <>
      <a
        style={{ margin: "0 auto" }}
        href="mailto:lchesal@imcritical.com?subject=SC24+Free+Passes"
      >
        <StaticImage
          style={{ maxWidth: "1200px", margin: "20px auto" }}
          src="../../images/sc24-imdata.webp"
          alt="Join Us in Booth #4407 - SC24 Atlanta, GA"
        />
      </a>
      <S.Wrapper style={{ height: height ? height : "100vh" }}>
        <S.HeroBg>
          <S.VideoBg
            autoPlay={autoplay ? "autoplay" : false}
            loop
            muted
            src={video.localFile.publicURL}
            poster={
              videoPoster
                ? videoPoster.localFile.childImageSharp.gatsbyImageData.images
                    .fallback.src
                : false
            }
          />
        </S.HeroBg>

        <div className="copy_container">
          <h1>{parse(title)}</h1>
          <div className="content">{parse(content)}</div>
        </div>
      </S.Wrapper>
    </>
  )
}
export default HomepageHero
